import React from 'react';
import { FaCog } from 'react-icons/fa';

const Tabs = ({ activeTab, handleTabClick }) => {
  const tabClass = (tab) =>
    `py-2 px-7 rounded-lg ${activeTab === tab ? 'bg-white border' : 'text-gray-500'}`;

  return (
    <div className="flex flex-col sm:flex-row gap-4 bg-gray-100 p-2 w-full sm:w-auto rounded-lg mr-8 pointer">
      <div
        onClick={() => handleTabClick('Account')}
        className={tabClass('Account')} style={{cursor:'pointer'}}
      >
        General
      </div>
      <div
        onClick={() => handleTabClick('Work Space')}
        className={tabClass('Work Space')} style={{cursor:'pointer'}}
      >
        Work Space
      </div>
    </div>
  );
};

const SettingsNav = ({ activeTab, handleTabClick }) => {
  const buttonClass = (tab) =>
    `text-sm font-medium py-4 px-6 ${activeTab === tab ? 'border-b-2' : 'text-gray-700'}`;

  const buttonStyle = (tab) => ({
    color: activeTab === tab ? '#6938ef' : '#4a5568', // '#4a5568' is Tailwind's gray-700 color
    borderBottomColor: activeTab === tab ? '#6938ef' : 'transparent',
  });

  return (
    <div className="bg-white shadow-lg mb-0 p-2 max-w-7xl mx-auto rounded">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="flex items-center ml-6 mb-2 sm:mb-0">
          <FaCog className="text-2xl text-gray-700 mr-2" />
          <h2 className="text-xl font-semibold">Settings</h2>
        </div>
        {/* <Tabs activeTab={activeTab} handleTabClick={handleTabClick} /> */}
      </div>
      <hr />
      <div className="flex flex-wrap border-b">
        <div onClick={() => handleTabClick('Account')} className={buttonClass('Account')} style={{cursor:'pointer', ...buttonStyle('Account')}}>
          Account
        </div>
        {/* <div onClick={() => handleTabClick('Pricing & Billing')} className={buttonClass('Pricing & Billing')} style={{cursor:'pointer', ...buttonStyle('Pricing & Billing')}}>
          Pricing & Billing
        </div>
        <div onClick={() => handleTabClick('Teams')} className={buttonClass('Teams')} style={{cursor:'pointer', ...buttonStyle('Teams')}}>
          Teams
        </div>
        <div onClick={() => handleTabClick('Connection')} className={buttonClass('Connection')} style={{cursor:'pointer', ...buttonStyle('Connection')}}>
          Connection
        </div>
        <div onClick={() => handleTabClick('API')} className={buttonClass('API')} style={{cursor:'pointer', ...buttonStyle('API')}}>
          API
        </div> */}
      </div>
    </div>
  );
};

export default SettingsNav;
