import { Navigate } from "react-router-dom";
import Dashboard from "../modules/Dashboard/index"
import AddWidgets from "../modules/Dashboard/AddWidgets/index"
// import Integrations from "../modules/Dashboard/AddWidgets/Integration";
// import SelectData from "../modules/Dashboard/AddWidgets/Widgets/SelectData";
// import CreateWidgets from "../modules/Dashboard/AddWidgets/Widgets/CreateWidgets";
import EditDashboard from "../modules/Dashboard/CompanyDashboard";
import CreateDashboard from "../modules/Dashboard/AddWidgets/CreateDashboard";
import CreateWidgets from "../modules/Dashboard/AddWidgets/CreateWidgets"

import AuthApi from "../Services/Auth";

export const Protected = ({children})=>{
    const token  = localStorage.getItem("token");
    if(token && AuthApi.isAuthenticatied() ){
        return children
    }
    else{
       return <Navigate to='/login' replace/>
    }
}

export const PROTECTED_ROUTES = [
    {
        path:"/",
        component:Dashboard,
        children:[
            {
                path:"",
                component:AddWidgets,
                children:[
                    
                    {
                      path:"create-widgets",
                      component:CreateWidgets
                    }
                    ,
                    {
                     path: "select-data/:id/create-widget",
                     component: CreateWidgets
                    }
                    
                ]
            }
        ]
    },
   
   
    {
        path:"/edit/dashboard/:id",
        component:EditDashboard
    }
    ,
    {
       path:"/create/dashboard",
       component:CreateDashboard
    }
]

