import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfileService from '../../../../Services/userProfile';
import CreatedDashboards from './createdDashboards';
import ProfileSideBar from '../../../../Shared/Dashboard/profileSideBar';

const CreateDashboard = () => {

  return (
    <div className="w-[100vw] min-h-[100vh] flex flex-col bg-background_color overflow-x-hidden">
      <div className="w-full h-[70px] flex flex-row justify-between px-4 lg:px-10 border-b-[1px] border-new_gray_100 bg-white">
        <div className="flex items-center gap-4 lg:gap-6">
          <div className="flex items-center gap-4 lg:gap-6">
            <img src="/media/add-widgets/baiynah.svg" className="h-[30px]" alt="Logo" />
            <div className="bg-new_gray_200 w-[1px] h-[35px]"></div>
          </div>
          <div className="flex items-center gap-2 lg:gap-4">
            <span className="text-[14px] text-new_gray_900 font-Inter font-semibold">Baiynah Workspace</span>
          </div>
        </div>
        <ProfileSideBar/>
      </div>
      <CreatedDashboards  />
    </div>
  );
};

export default CreateDashboard;
