import { ReactComponent as Baiynah } from "../../assets/LandingPage/baiynah.svg";

export default function Footer() {
  return (
    <footer className="relative text-white bg-black overflow-hidden font-Inter pt-[64px]">
      <div className="relative z-10 py-10 px-4 sm:px-6 lg:px-8 flex flex-col items-center">
        <div className="mb-6 text-center flex items-center justify-center">
          <div className="mb-6 text-center flex items-center justify-center">
            <img
              src="/media/login/baiynah-white.svg"
              alt="Bayinah logo"
              className="h-12 w-12"
            />
            <div className="border-l-2 border-white h-12 ml-2 mr-2"></div>
            <div className="mt-2">
              <span className="block text-xs sm:text-sm">
                Visualize Your Data
              </span>
              <span className="block text-xs sm:text-sm font-medium">
                With Bayinah
              </span>
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <a href="/home" className="hover:text-gray-300">
            Home
          </a>
          <a href="/product" className="hover:text-gray-300">
            Product
          </a>
          <a href="/pricing" className="hover:text-gray-300">
            Pricing
          </a>
          <a href="/blog" className="hover:text-gray-300">
            Blog
          </a>
          <a href="/contact" className="hover:text-gray-300">
            Contact
          </a>
        </div>
      </div>

      <div className="relative bottom-0 inset-x-0 w-full opacity-30">
        <Baiynah className="w-full h-auto" aria-hidden="true" />
      </div>
    </footer>
  );
}
