import React, { useEffect, useState } from 'react'
import {useDispatch} from "react-redux"
import AllDataSources from '../../../../../../../../Services/data-sources'
import Spinner from '../../../../../../../../components/spinner'
import Sheeturl from './sheeturl'
import { GetDataSets } from '../../../../../../../../Redux/dataSourcesSlice'
const SelectSheet = () => {


  const [sheetURl,setSheetURL] = useState("")
  const [allSpreadSheets,setAllSpreadSheets] = useState([])
  const [googleAccounts,setGoogleAccounts] = useState([])

  const [selectedAccountId,setSelectedAccountId] = useState()
  const [isAddAccountLoading,setIsAccountLoading] = useState(false)
  const [isLoading,setisLoading] = useState(false)
  const [isGoogleAccountLoading,setisGoogleAccountLoading]  = useState(false)


  const handleURL = async (e) => {

    const {value} = e.target;
    setSheetURL(value)
  }


  const getData = async () => {
    try {
      setisLoading(true)
      const resp = await AllDataSources.getSheet({sheetURl,selectedAccountId})
  
      if(resp){
        console.log(resp)
        setAllSpreadSheets(resp?.response)
        setisLoading(false)
      }
      
     } catch (error) {
      setisLoading(false)
     }
  }

  const getGoogleAccounts = async () => {
    try {
      setisGoogleAccountLoading(true)
      const resp = await AllDataSources.getGoogleAccounts()
  
      if(resp){
        console.log(resp)
        setGoogleAccounts(resp?.accounts)
        setisGoogleAccountLoading(false)
        setSelectedAccountId(resp?.accounts[0]?.id)
      }
      
     } catch (error) {
      setisGoogleAccountLoading(false)
     }
  }

  const handleConnect = async () => {
    try {
      setIsAccountLoading(true);
      const res = await AllDataSources.googleSheet();

      if (res) {
        setIsAccountLoading(false);

        const width = 500;
        const height = 600;
        const left = window.screenX + (window.innerWidth - width) / 2;
        const top = window.screenY + (window.innerHeight - height) / 2;

        window.open(
          res, // URL from response
          "GoogleSheetPopup", // Name of the popup window
          `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars`
        );
        // window.open(res);
      }
    } catch (error) {
      setIsAccountLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsAccountLoading(true);
      const res = await AllDataSources.deleteGoogleAccount(selectedAccountId);

      if (res) {
        setIsAccountLoading(false); 
        
        const filterData = googleAccounts?.filter((item,index)=>{
          return item.id !== selectedAccountId

        })

        setGoogleAccounts(filterData)
       
      }
    } catch (error) {
      setIsAccountLoading(false);
    }
  }


  useEffect(()=>{
    if(!sheetURl) return
    getData()
  },[sheetURl])

  useEffect(()=>{
    getGoogleAccounts()
  },[])

  const handleSelectAccount = (e) => {
    const selectedId = e.target.value;
    console.log(selectedId, "VALUE");
    setSelectedAccountId(selectedId);
  }


  return (
    <div className='w-full h-full flex flec-col px-6 ' >
        <div className='w-[800px] h-auto flex flex-col gap-6 ' >
           <div className='flex flex-row gap-8' >
           <div className='w-[70%] flex flex-row gap-4 ' >
           <select onChange={(e) => handleSelectAccount(e)}   aria-label="Select a Google Account" className='w-[90%] h-[45px] border-[1px] border-[#D0D5DD] px-4 outline-none rounded-md ' >
                {
                   googleAccounts.length > 0 && googleAccounts.map((item,index)=>{
                   
                    return (
                      <option key={index} value={item?.id} >{item?.email}</option>
                    )
                  })
                }
               
            </select>
          
              <button onClick={handleDelete} className='w-[40px] h-[43px] bg-[#ff2d2d]  inline-flex gap-2 items-center justify-center rounded-md active:scale-90 transition-all ' >
                  <img src='/media/dashboard/delete-white.svg'/>
              </button>
           </div>
              <button className='w-[30%] h-[45px] bg-[#D9DCE1] hover:bg-[#d9dce1cc] inline-flex gap-2 items-center justify-center rounded-md active:scale-90 transition-all ' >
                  <img src='/media/data-sources/plus.svg'/>
                  <span onClick={handleConnect} className=' font-Inter font-medium ' >Add Account</span>
              </button>
           
           </div>

          

           <div className='w-full h-[45px] flex items-center justify-center border-[1px] outline-none rounded-md border-[#D0D5DD] px-4' >
            {
              isLoading ? <Spinner/> :
              <input onChange={handleURL} type='text' placeholder='Add Link to Sheet' className='w-full outline-none h-full' />

            }
           </div>

            <div className='w-full h-auto flex flex-col  border-[1px] border-[#D0D5DD] rounded-md  ' >
             {
             allSpreadSheets && allSpreadSheets.length > 0 && allSpreadSheets.map((item,index)=>{
                return(
                   <Sheeturl item={item} sheet={sheetURl} selectedAccountId={selectedAccountId} />
                )
              })
             }
             
             
            
            </div>
        </div>
    </div>
  )
}

export default SelectSheet