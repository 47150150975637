import React, { useState,useEffect } from 'react'
import {motion,useAnimationControls,AnimatePresence} from "framer-motion"
import AllWidgetsModal from './allWidgetsModal'
import MeasuresColumns from './MeasureColumns/measuresColumns'
import DataSet from './DataSet/dataSet'
import DataSources from './DataSources'
import "./index.css"
import { setisSideBarActive } from '../../../../../Redux/SheetSlice'
import { useDispatch, useSelector } from 'react-redux'

const containerVariants = {
  close:{
    width:"80px",
    transition:{
      type:"spring",
      damping:15,
      duration:0.2
    }
  },
  open:{
    width:"360px",
    transition:{
      type:"spring",
      damping:15,
      duration:0.2
    }
  }
}

const DataSideBar = () => {

  const dispatch = useDispatch()

  // const [isSideBarActive,setisSideBarActive] = useState(false)
  const isSideBarActive = useSelector((state)=>state.sheet.isSideBarActive)
  const dataSources = useSelector((state)=>state.sources.dataSets)
  const containerControls = useAnimationControls()

  const connectedSources = []


  useEffect(()=>{
    if(isSideBarActive){
      containerControls.start("open")
    }
    else{
      containerControls.start("close")
    }
  },[isSideBarActive])

  const handleMinimize = ( ) => {
    dispatch(setisSideBarActive(!isSideBarActive))
  }

  for(let keys in dataSources){
    connectedSources.push(keys)
  }
  // console.log(dataSources,"DATA SOURCES")


  return (
    <motion.div
     variants={containerVariants}
     animate={containerControls}
     initial="close"
     className={` h-full border-r-[1px] border-new_gray_200 flex flex-col relative px-1 `} >
        {/**Bar Header**/}
        <div className={`w-full h-[100px] border-b-[1px] border-new_gray_200 flex ${isSideBarActive ? "flex-row gap-8 " : "flex-col-reverse gap-2 "} items-center justify-center  px-6  `} >
         
            <div 
            className={` ${isSideBarActive ? "w-[230px] max-w-auto h-[35px] " : "w-[30px] h-[30px] justify-center "}   bg-[#F4F3FF]  whitespace-nowrap flex flex-row items-center rounded-full px-2 py-1 gap-2 `} >
             
               <span className={`text-center ${isSideBarActive ? "w-[200px]" : " w-[20px] h-[20px] inline-flex items-center justify-center "} bg-white rounded-full lg:text-[12px] 2xl:text-[14px] text-new_purple_700 font-Inter font-semibold px-2 py-1 `} >{connectedSources?.length} {isSideBarActive  ? "Data resource Connect" :""}</span>
            <img  style={{display:isSideBarActive ? "inline-flex":"none"}} src='/media/add-widgets/cancel.svg' className='h-[20px] cursor-pointer hover:scale-110 active:scale-90  transition-all '/>
          </div>
          
          <img onClick={handleMinimize} src='/media/add-widgets/minimize-menu.svg' className="h-[25px] hover:scale-110  active:scale-90 cursor-pointer transition-all " />
        </div>
        {/**Modals**/}
        <div
         className={`w-full ${isSideBarActive? " opacity-100 ": "opacity-0 pointer-events-none "} h-[calc(100%-80px)] overflow-y-auto data-sidebar  flex flex-col gap-2 pt-4 mt-2 mb-2  items-center  `} >
          {/* *Widgets Modal**/}
          <AllWidgetsModal isSideBarActive={isSideBarActive} />
          {/* <MeasuresColumns isSideBarActive={isSideBarActive}/> */}
          <DataSet isSideBarActive={isSideBarActive}/>
          <DataSources isSideBarActive={isSideBarActive}/>
          <div className='w-full h-[200px] mb-[50px] block'></div>
          
        </div>

    </motion.div>
  )
}

export default DataSideBar