import React from 'react'
import NumberDrop from './number/numberDrop'
import GaugeDrop from './gauge/gaugeDrop'
import LineDrop from './line/lineDrop'
import BarDrop from './bar/lineDrop'
import { useSelector } from 'react-redux'

const DropZone = () => {

  const currentChart = useSelector((state)=>state.charts.currentChart)
  console.log(currentChart,"CURRENT")

  const handleZone = () => {
    switch(currentChart){
      case "number" : 
       return <NumberDrop/>;
      case "gauge":
       return <GaugeDrop/>;
      case "line":
       return <LineDrop/>;
      case "bar":
       return <BarDrop/>;
       case "table":
       return <LineDrop/>;
      default :
      return <NumberDrop/>
    }
  }

  return (
    <>
        {
          handleZone()
        }



    </>
  )
}

export default DropZone