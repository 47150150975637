import React, { useEffect, lazy, Suspense, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import "./index.css"
import DashboardBuilder from "src/Helpers/DashboardBuilder";
import Menu2Chart from "../AddWidgets/CreateWidgets/ChartType/charts/menu2Chart";
import Dashboard from "src/Services/dashboard";
import Spinner from "../../../components/spinner";
import { useParams } from "react-router-dom";

 
const WidgetsPlayground = React.memo(() => {

    const navigate = useNavigate()

    const { id: dashboardID } = useParams();
    const [dashboardWidgets, setDashboardWidgets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { 
        initPlayground, 
        allowDrop,
        dragWidget,
        startResizing,
        getDashboardWidgetsCoordinates
    } = DashboardBuilder();

    // const allWidgets 

    const dashboardWidgetss = [
        {
            id: "widget-1",
            name: "Test widget one",
            colspan: 1,
            rowspan: 1,
            offsetLeft: 0,
            offsetTop: 0
        },
        {
            id: "widget-2",
            name: "Test widget two",
            colspan: 2,
            rowspan: 1,
            offsetLeft: 1,
            offsetTop: 0
        },
        {
            id: "widget-3",
            name: "Test widget three",
            colspan: 2,
            rowspan: 2,
            offsetLeft: 3,
            offsetTop: 1
        },
    ];


    const saveDashboardChanges = async () => {
        console.log(JSON.stringify(getDashboardWidgetsCoordinates(dashboardID)));

        console.log(dashboardID);

        await Dashboard.saveDashboardChanges(JSON.stringify(getDashboardWidgetsCoordinates(dashboardID)));
    }

    const addWidgetToPlayground = async (event) => {     
        console.log(event.detail);     
        const newWidgetResponse = await Dashboard.loadNewWidgetToDashboard(event.detail.widgetId, dashboardID);

        setDashboardWidgets(prevState => [
            ...prevState,
            newWidgetResponse.data
        ]);
    }
    

  useEffect(() => {

        async function loadPlaygroundData () {

            // Fetch Dashboard specific widgets only
            const dashboardWidgetsResponse = await Dashboard.getDashboardSpecificWidgets(dashboardID);

            setDashboardWidgets(pState => [
                ...dashboardWidgetsResponse.data
            ]);
        }

        loadPlaygroundData();

        window.addEventListener('addWidgetToPlayGround', addWidgetToPlayground);

        return () => window.removeEventListener("addWidgetToPlayGround", addWidgetToPlayground);
        

    }, []);
    
    useEffect(() => {

        initPlayground(dashboardWidgets);

    }, [dashboardWidgets]);

    useEffect(() => {
        console.log("FirstComponent mounted or re-rendered");
    });

  return (
    <div className="w-[100%]  h-full pt-3 pb-12 px-12 overflow-y-auto  z-50 ">

        <div className="w-full flex items-center justify-end mb-3">

            <div onClick={ saveDashboardChanges } className="w-[150px] h-[40px] bg-blue-400 rounded-md cursor-pointer active:scale-95 transition-all  text-white font-Inter inline-flex items-center justify-center " >
                
            {
                isLoading ? <Spinner/> :   "Save Changes"
            }
            </div>

        </div>
     

        <div className={`min-h-12 border p-2 mx-auto bg-white`} id="my-playground" >
            <div class="w-[100%] min-h-[768px] h-[calc(100vh-200px)]  grid grid-cols-12 gap-2 relative  " onDragOver={ allowDrop }>

                {
                    dashboardWidgets.map((w, i) => {
                        //This will import the component dynamically 
                        const ChartComponent = lazy (()=>import(`../AddWidgets/CreateWidgets/ChartType/charts/${w.widgetId.chartType}Chart`));
                        
                        return (
                        <div 
                            key={ w._id }
                            className={`widget box-border z-[1001]  cursor-pointer bg-gray-300 rounded border-4 border-white absolute flex items-center justify-center overflow-hidden`} 
                            style={{ 
                                left: `calc(calc(100%/12)*${ w.offsetLeft })`, 
                                top: `calc(calc(100%/12)*${ w.offsetTop })`,
                                width: `calc(calc(100%/12)*${ w.colspan })`,
                                height: `calc(calc(100%/12)*${ w.rowspan })`
                            }}
                            id={ `w${ w.widgetId._id }` }
                            data-id={ w.widgetId._id } 
                            draggable={ true }
                            onDragStart={ () => false }
                            onMouseDown={ dragWidget }>
                                {/* { w.widgetId.title } */}
                                <Suspense  fallback={<Spinner />}>
                                  <ChartComponent chartData={w.widgetData}/>
                                </Suspense>
                            <div 
                                className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                                onMouseDown={ startResizing }></div>
                        </div>
                        )
                    })
                }

                    {
                    dashboardWidgets?.length == 0  &&
                    <div className="w-[500px] h-[500px]    rounded-md  flex  " >
                        <div className="w-[500px] h-[450px]  bg-new_gray_200 shadow-sm p-8 flex items-center justify-center flex-col gap-6 " >
                        <img className=" h-[200px]" src="/media/dashboard/widget-1.svg"/>
                        <span className="text-[16px] font-Inter " >Start adding widgets to fill up your dashboard </span>
                        <div onClick={()=>navigate(`/create-widgets`)} className='flex flex-row gap-2 rounded-md border-[1px] border-new_gray_300 px-4 py-2 bg-white shadow-buttons_shadow cursor-pointer active:scale-90 transition-all ' >
                            <img src='/media/dashboard/plus-blue.svg'/>
                            <span className='font-Inter font-semibold' >Add Widget</span>
                        </div>   
                        </div>
                    </div>
                    }

               

                {/* <div 
                    className="widget box-border w-[calc(calc(100%/12)*2)] h-[calc(calc(100%/12)*2)] z-[1001] left-[calc(calc(100%/12)*2)] cursor-pointer bg-gray-300 rounded border-4 border-white absolute flex items-center justify-center overflow-hidden" 
                    id="widget-2"
                    data-id={ 4 } 
                    draggable={ true }
                    onDragStart={ () => false }
                    onMouseDown={ dragWidget }>
                        <img src="/media/chart2.jpg" />
                    <div 
                        className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                        onMouseDown={ startResizing }></div>
                </div> */}

                {/* <div 
                    className="widget box-border w-[calc(calc(100%/12)*1)] h-[calc(calc(100%/12)*1)] z-[1001] left-[calc(calc(100%/12)*4)] cursor-pointer bg-gray-300 rounded border-4 border-white absolute flex items-center justify-center overflow-hidden" 
                    id="widget-3"
                    data-id={ 5 } 
                    draggable={ true }
                    onDragStart={ () => false }
                    onMouseDown={ dragWidget }
                    >
                        <img src="/media/chart3.jpg" />
                    <div 
                        className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                        onMouseDown={ startResizing }></div>
                </div> */}

                <div className="box-border  absolute pointer-events-none z-[1000] hidden bg-blue-800/10 border border-dashed border-blue-500 rounded" id="my-cellHighlighter"></div>
            </div>
        </div>
      
    </div>
  );
});

export default WidgetsPlayground;