import React, { useState } from 'react'
import SelectSheet from './selectSheet'
import ConnectData from './connectData1'
const GoogleSheet = ({setCurrentTab}) => {

   const isLogedIn = localStorage.getItem("googleLogined")

    const [isLoged,setIsLoged] = useState(true || isLogedIn)

    console.log(isLoged,"IS GOOGLE LOGGED")

  return (
    <div className="w-full h-full flex flex-col  " >
       <div className="w-full h-[100px] flex items-center justify-between  gap-4 border-b-[1px] px-6 ">
         <div className='flex gap-4 items-center ' >
            <img src='/media/data-sources/grid1.jpg' className='h-[35px]' />
            <div className=' inline-flex flex-col  ' >
            <span className='text-[20px] font-Inter font-semibold ' >Choose File</span>
            <p className='text-[12px] font-Inter font-normal ' >Choose The Spead Sheet U would like to visualize</p>
            </div>
         </div>
         <h2 onClick={()=>setCurrentTab("AllSources")} className=' cursor-pointer ' >Back</h2>
      </div>

      <div className="w-full h-[calc(100%-70px)]   pt-6">
         {
            !isLoged ?
            <ConnectData setIsLoged={setIsLoged} />
            :
            <SelectSheet/>
         }
      </div>

     

    </div>
  )
}

export default GoogleSheet