import React, { useState } from "react";
import Spinner from "../components/spinner"

const DeleteConfirmation = ({isActive,setIsActive,handleDelete,isLoading}) => {

    
   

  return (
    <>
     
      <div
        id="deleteModal"
        tabindex="-1"
        aria-hidden="true"
        className={` ${isActive ? "visible" : "hidden"} backdrop-blur-[3px] bg-gray-500 bg-opacity-30  rounded-md  w-full h-[100vh] fixed top-0 right-0 left-0 z-50 flex justify-center items-center  `}
      >
        <div className=" p-4 w-full max-w-md h-full md:h-auto">
          <div className="  p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="deleteModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <svg
              className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <p className="mb-4 ">
              Are you sure you want to delete this item?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <button
              onClick={()=>setIsActive(false)}
                data-modal-toggle="deleteModal"
                type="button"
                className="w-1/2 py-2 px-3 border-[1px] border-new_gray_300 rounded-md font-Inter font-semibold active:scale-95 transition-all"
              >
              No, Cancel
              </button>
              <button
              onClick={()=>handleDelete()}
                type="submit"
                className=" w-1/2 border-[1px] px-4 py-2 disabled:cursor-not-allowed  bg-new_purple_600 text-white rounded-md font-Inter font-normal   transition-all"
              >
                {
                      isLoading ? <Spinner className /> : "  Yes, I'm sure"
                    }
            
              
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirmation;
