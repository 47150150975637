import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import AddWidgets from '../Services/add-widgets'

const initialState = {
  isAddWidgetLoading:false,
}

export const AddWidget = createAsyncThunk("create-widget",async(payload)=>{
    const res = await AddWidgets.createWidget(payload);
    return res;
})
export const WidgetSlice = createSlice({
  name: 'Widget',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(AddWidget.pending,(state,action)=>{
           state.isAddWidgetLoading = true
      })
      .addCase(AddWidget.fulfilled,(state,action)=>{
        state.isAddWidgetLoading = false

      })
      .addCase(AddWidget.rejected,(state,action)=>{
        state.isAddWidgetLoading = false

      })

      

   
  },
})

export const {} = WidgetSlice.actions

export default WidgetSlice.reducer