import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DropTarget from "./DropTarget";
import DropYTarget from "./DropXTarget";
import { DimensionalGraph ,resetBar } from "../../../../../../../Redux/chartsSlice";
import { useDispatch } from "react-redux";
import { AddWidget } from "src/Redux/widgetSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const ItemTypes = {
  NUMBER: "number",
  STRING: "string",
};

const BarDrop = () => {
  const dispatch = useDispatch();
  const params = useParams()


  const [xAxis, setxAxis] = useState(" ");
  const [yAxis, setyAxis] = useState([]);

  const submitWidget = (event) => {

    const dashboardId = params?.id;
  
    // Optional validation for xAxis and yAxis
    if (!xAxis == " " && yAxis.length > 0) {
     
      const metaData = {
        dashboardId,
        title:event.detail?.title,
        xAxis: xAxis?.["value"],
        yAxis: yAxis?.map(item => item.value) || [],
        dataId:xAxis?.dataId,
        chartType: "bar",  // "chatType" should probably be "chartType"
      
      };

      dispatch(AddWidget(metaData)).then((resp)=>{
        if(resp?.payload.message == "Widget added successfully"){
          toast.success(`Widget Saved Successfully`, {
            position: "top-right",
           },5000)
        }
        else{
          toast.error(`Error while saving widget`, {
            position: "top-right",
           },5000)
        }
      })
    
      console.log(metaData, "DASHBOARD DATA");
    // Here you might also call an API or update some state with this metaData
    }else{
      console.warn("DATA MISSING")
    }
  
  };

  const handleDelete = (id) => {
    const filter = yAxis.filter((item, index) => {
      return item._id !== id;
    });
    setyAxis(filter);
  };

  useEffect(() => {
    if (!xAxis == " " && yAxis.length > 0) {
      //Array which contain two dimensional items
      const newArray = [];

      const newXAxis = {
        dataId: xAxis?.dataId,
        columnName: xAxis?.column,
        axis: xAxis?.axis,
      };

      newArray.push(newXAxis);



      //Maping
      yAxis?.map((item, index) => {
        const newItem = {
          dataId: item?.dataId,
          columnName: item?.column,
          axis: item?.axis,
        };
        newArray.push(newItem);
      });

      let payload = {
        type:"bar",
        data:newArray
      }

      dispatch(DimensionalGraph(payload))
    }
    else{
      dispatch(resetBar())
    }
  }, [xAxis, yAxis]);

  useEffect(() => {
    const handleSubmitEvent = (event) => {
      submitWidget(event);  // Invoke your submitWidget function when event is caught
    };
  
    window.addEventListener("submitWidgetData", handleSubmitEvent);
  
    // Cleanup to remove the listener on unmount
    return () => {
      window.removeEventListener("submitWidgetData", handleSubmitEvent);
    };
  });  

 
  return (
    <div className="w-full h-auto  flex flex-col items-center gap-4  ">
      <DropTarget
        accept={ItemTypes.STRING}
        label={"X-axis"}
        onDrop={setxAxis}
        item={xAxis}
        setValue={setxAxis}
        handleDelete={() => setxAxis(" ")}
      />
      <DropYTarget
        accept={[ItemTypes.NUMBER, ItemTypes.STRING]}
        label={"Y-axis"}
        onDrop={setyAxis}
        item={yAxis}
        setValue={setyAxis}
        handleDelete={handleDelete}
      />
      
    </div>
  );
};

export default BarDrop;
