import React, { useState,useEffect } from 'react'
import { Outlet,useParams,useNavigate } from 'react-router-dom';
import UserProfile from '../../../Services/userProfile';
import CreateWidgets from './CreateWidgets';
import ProfileSideBar from 'src/Shared/Dashboard/profileSideBar';

const userProfile = new UserProfile().getUserProfile()

const AddWidgets = () => {

  const Navigate = useNavigate()
  const {id } = useParams();

  const [isSelectData,setIsSelectData] = useState(false)


  useEffect(()=>{
    if(window.location.pathname == "/dashboard/select-data"){
     console.log(true)
     setIsSelectData(true)
    }
    else{
     setIsSelectData(false)
    }
   },[])

   const navigateBuilder = () => {
    Navigate(`/edit/dashboard/${id}`)
  }

  const navigateHome =() => {
    Navigate(`/create/dashboard`)
  }

  
  return (
    <div className='w-full h-[100vh] flex flex-col overflow-hidden  ' >
      {/***Header*/}
      <div className='w-full h-[70px] flex flex-row  justify-between px-10   border-b-[1px] border-new_gray_100 ' >
        {/**Header Left Side**/}
         <div className='sm:w-full lg:w-[60%] flex flex-row gap-6 items-center ' >
           {/**Website Logo**/}
            <div className='flex flex-row items-center gap-6  ' >
              <img onClick={navigateHome} src='/media/add-widgets/baiynah.svg'  className='h-[30px] cursor-pointer'  />
              <div className=' bg-new_gray_200 w-[1px] h-[35px] ' ></div>
            </div>
            {/***Active Tabs**/}
            <div className='flex flex-row items-center gap-4' >
              <span onClick={navigateBuilder}  className='text-[14px] cursor-pointer text-new_gray_900 text-new  font-Inter font-semibold'>Dashboard</span>
              <span className='w-[1px] h-[22px] bg-new_gray_200  rotate-12' ></span>
              <span className='text-[14px] text-new_gray_900 text-new  font-Inter font-semibold' >Widget</span>
              {
                isSelectData == true && 
                <>
                <span className='w-[1px] h-[22px] bg-new_gray_200  rotate-12' ></span>
                <span className='text-[14px] text-new_gray_900 text-new  font-Inter font-semibold' >Select Data</span>
                </>
              }
            </div>
         </div>
        {/**Header Right Side**/}
         <ProfileSideBar/>
      
      </div>
      {/* <CreateWidgets/> */}
      <Outlet/>
    </div>
  )
}

export default AddWidgets