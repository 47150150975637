import React,{useState} from 'react'
import AuthApi from '../../../Services/Auth';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/spinner';

const Verification = ({STATUS_TYPE,setForm,form}) => {

    const [isLoading, setisLoading] = useState(false);
    const [isError, setisError] = useState(false);
    const [message,setMessage] = useState("")
    const [code, setCode] = useState({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
    });

    const { email } = form;

    const handleVerification = async () => {
        setisLoading(true)
        setisError(false)
        setMessage(" ")
        //Concatenate all the digites
        const verificationCode = Object.values(code).join("");
        setForm(prev=>({
          ...prev,
          verifyCode:verificationCode
        }))
       
        const payload = {
          email: email,
          verifyCode: verificationCode,
        };
    
        try {
          const resp = await AuthApi.emailVerification(payload);
          console.log(resp,"RESP");
          setForm(prev=>({
            ...prev,
            status:STATUS_TYPE.VERIFIED
        }));
          setisLoading(false)
          setisError(false)
          setMessage(" ")
        
        } catch (error) {
          setisLoading(false);
          setisError(true);

          if(!error.response){
            setMessage("🌐 Newtork Issue")
          }
          else{
            setMessage(error?.response?.data?.error)
          }
          // setMessage(error?.response?.data?.error)
          setCode(prev=>({
            ...prev,
            first:"",
            second:"",
            third:"",
            fourth:"",
            fifth:"",
            sixth:""
          }))
        }
      };
    
      const disabled =
        !code.first ||
        !code.second ||
        !code.third ||
        !code.fourth ||
        !code.fifth ||
        !code.sixth;

    const handleNumber = (e) => {
        const { value, name } = e.target;
    
        if (value.length > 1) {
          const newValue = value.slice(0, 1);
          setCode({
            ...code,
            [name]: newValue,
          });
        } else {
          setCode({
            ...code,
            [name]: value,
          });
        }
      };


  return (
    <div className="sm:w-[70%] md:w-[70%]  lg:max-w-[600px]  flex flex-col items-center gap-6 ">
    {/**Logo Baiynah**/}
    <div className="w-full flex flex-col gap-3 items-center p-4  ">
      <img
        src="/media/login/baiynah-logo.svg"
        className="mb-[10px] mt-[20px] "
      />
      <h2 className=" sm:text-[22px] 2xl:text-[30px] font-Inter font-semibold ">
        Please check email
      </h2>
      <span className="sm:text-[14px] 2xl:text-[16px] text-center font-Inter font-normal text-[new_gray_700] text-[#344054] ">
        We send the <b>6-digit</b> code to <b>{email}</b> for
        verification
      </span>
    </div>
    {/***Verification Code***/}
    <div className="w-full  ">
      {!isError == " " && (
        <Alert message={isError} closeAction={() => setisError(false)} />
      )}
      <div className="flex flex-row items-center justify-center gap-4">
        <div className="w-[45%] h-[50px] border-[1px] flex flex-row  border-border_fields  rounded-md ">
          <input
            onChange={handleNumber}
            name="first"
            type="number"
            value={code.first}
            placeholder="0"
            max={1}
            min={1}
            maxLength={1}
            className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
          />
          <input
            onChange={handleNumber}
            name="second"
            type="number"
            value={code.second}
            placeholder="0"
            max={1}
            min={1}
            maxLength={1}
            className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
          />
          <input
            onChange={handleNumber}
            name="third"
            type="number"
            value={code.third}
            placeholder="0"
            max={1}
            min={1}
            maxLength={1}
            className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
          />
        </div>
        <span className="w-[10%] h-[4px]  bg-border_fields"></span>
        <div className="w-[45%] h-[50px] border-[1px] flex flex-row  border-border_fields  rounded-md ">
          <input
            onChange={handleNumber}
            name="fourth"
            type="number"
            value={code.fourth}
            placeholder="0"
            max={1}
            min={1}
            maxLength={1}
            className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
          />
          <input
            onChange={handleNumber}
            name="fifth"
            type="number"
            value={code.fifth}
            placeholder="0"
            max={1}
            min={1}
            maxLength={1}
            className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
          />
          <input
            onChange={handleNumber}
            name="sixth"
            type="number"
            value={code.sixth}
            placeholder="0"
            max={1}
            min={1}
            maxLength={1}
            className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
          />
        </div>
      </div>
    </div>

    <div className="w-full flex flex-col ">
      <button
        disabled={disabled || isLoading}
        onClick={handleVerification}
        className={`p-3 disabled:cursor-not-allowed bg-new_purple_600 ${
          disabled
            ? "bg-hovered_new_purple_600"
            : "hover:bg-hovered_new_purple_600 active:scale-95"
        }  transition-all text-white font-Inter rounded-md `}
      >
        {isLoading ? <Spinner /> : "Verify Email"}
      </button>
      <div className="flex flex-row gap-1 items-center justify-center mt-[30px] ">
        <span className="text-[14px] font-Inter text-new_gray_500">
          Added wrong email?
        </span>
        <span className="text-[14px] font-Inter text-new_purple_600">
          <b>Change Email</b>
        </span>
      </div>
    </div>

    {/* <LoginForm /> */}
    <div className="w-full h-[30px] block"></div>
  </div>
  )
}

export default Verification