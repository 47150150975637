import { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AUTH_ROUTES, Auth } from "./Routes/Auth";
import { PROTECTED_ROUTES, Protected } from "./Routes/Protected";
import HomePage from "./screens/homepage/Homepage";
import NotFoundPage from "./screens/error404/Error404";
import { store } from "./store";
import Blog from "./screens/blog/Blog";
import Articles from "./screens/articles/Articles";
import HelpCenter from "./screens/helpcenter/HelpCenter";
import ContactPage from "./screens/contact-us";
import Pricing from "./screens/pricing";
import Services from "./screens/servicespage/Services";
import Dashboard from "./screens/dashboard/Dashboards";
import Kpi from "./screens/kpi/Kpi";
import { ToastContainer } from 'react-toastify';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./config/constant";
import Profile from './modules/Dashboard/AddWidgets/CreateDashboard/Ui-setting/userProfile'
import PageLoading from "./Shared/Screens/pageLoading";

function App() {

  const [loading, setLoading] = useState(false);

  //  // Track changes in Suspense loading status
  //  useEffect(() => {
  //   const handleStartLoading = () => setLoading(true);
  //   const handleStopLoading = () => setLoading(false);

  //   // Add a small delay to simulate loading and prevent flickering
  //   setTimeout(() => {
  //     handleStartLoading();
  //   }, 100); // Adjust the delay as needed

  //   return () => {
  //     // Cleanup if needed
  //     handleStopLoading();
  //   };
  // }, []);


  return (
   <>
   {
    loading ? ( <PageLoading/> ):(
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <DndProvider backend={HTML5Backend} >
    <ToastContainer autoClose={1500} />
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<Articles />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/webdashboard" element={<Dashboard />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/kpi" element={<Kpi />} />
        <Route path="/profile" element={<Profile />} />

        {/**All the Authention Routes Like Login and Register**/}
        {AUTH_ROUTES.map(({ path, component: Component }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <Auth>
                <Component />
              </Auth>
            }
          />
        ))}

        {PROTECTED_ROUTES.map(
          ({ path, component: Component, children }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Protected>
                  <Component />
                </Protected>
              }
            >
              {children &&
                children.map(
                  (
                    {
                      path: pathChild,
                      component: ComponentChild,
                      children: grandChildren,
                    },
                    idx
                  ) => (
                    <Route
                      key={idx}
                      path={pathChild}
                      element={<ComponentChild />}
                    >
                      {grandChildren &&
                        grandChildren.map(
                          (
                            {
                              path: pathGrandchild,
                              component: ComponentGrandchild,
                            },
                            idx
                          ) => (
                            <Route
                              key={idx}
                              path={pathGrandchild}
                              element={<ComponentGrandchild />}
                            />
                          )
                        )}
                    </Route>
                  )
                )}
            </Route>
          )
        )}
      </Routes>
    </Provider>
    </DndProvider>
  </GoogleOAuthProvider>
    )
   }
   

   </>
  );
}

export default App
