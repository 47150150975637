import React from 'react'
import { ImpulseSpinner } from "react-spinners-kit";

const PageLoading = () => {
  return (
    <div className='flex w-[100vw] h-[100vh] justify-center items-center gap-1 bg-black'>
      <div className='bg-no-repeat w-[430px] h-[215px] flex-shrink-0 gap-6 flex items-center  justify-center flex-col pt-[20px]  '  >
        <img src="media/login/baiynah-white.svg" className='w-[180px] h-[60px] ' />
        <ImpulseSpinner
         size={70}
        />
      </div>
    </div>
  )
}

export default PageLoading  