import React, { useState } from 'react'
import { motion,AnimatePresence } from 'framer-motion'

const AllWidgetsModal = ({isSideBarActive}) => {

  const [isMinimize,setIsMinimize] = useState(true)

  const handleMinimize = () => {
    setIsMinimize(!isMinimize)
  }

  const modalsAnimation = {
    hidden:{
      width:0,
      scale:0,
      opacity:0,
      transition:{
        duration:0.2,
        type:"spring",
        damping:15,
      }

    },
    show:{
      width:"90%",
      scale:1,
      opacity:100,
      transition:{
        duration:0.2,
        type:"spring",
        damping:15,
      }


    }
  }

  return (
    <AnimatePresence>
      {
        isSideBarActive &&
        <motion.div
        variants={modalsAnimation}
        initial="hidden"
        animate="show"
        exit="hidden"
        className=' h-auto border-[1px] border-new_gray_200 rounded-xl flex-nowrap flex flex-col' >
            {/**header**/}
            <div className='w-full h-[50px] bg-[#FAFAFF] flex flex-row items-center justify-between border-b-[1px] border-new_gray_100 px-6  ' >
              <div className='flex items-center flex-row gap-4' >
                <img src='/media/add-widgets/flower.svg' className='lg:h-[15px] 2xl:h-[18px]' />
                <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-semibold ' >Widget</span>
              </div>
              <div onClick={handleMinimize} className='flex relative' >
                <img  src='/media/add-widgets/minus.svg' className={`lg:h-[20px] 2xl:h-[20px] absolute ${isMinimize ? "rotate-90":"rotate-0"} transition-all  cursor-pointer hover:scale-110 transition-all `} />
                <img  src='/media/add-widgets/minus.svg' className='lg:h-[20px] 2xl:h-[20px] cursor-pointer hover:scale-110 transition-all ' />

              </div>
            </div>
            {/***data*/}
            <div className={`w-full ${isMinimize ? "h-0 opacity-0 pointer-events-none " :" min-h-[10px] max-h-auto mt-[15px] mb-[15px]"} transition-all  flex flex-col gap-4 px-6 `} >
                {/* <div className='flex flex-row justify-between ' >
                  <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-medium ' >Revenue</span>
                  <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-medium ' >78</span>
                </div>
                <div className='flex flex-row justify-between ' >
                  <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-medium ' >Sales</span>
                  <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-medium ' >78</span>
                </div>
                <div className='flex flex-row justify-between ' >
                  <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-medium ' >Profit</span>
                  <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-medium ' >78</span>
                </div>
               */}
               <span>No Any Widget is Created yet</span>
            </div>
        </motion.div>
      }
    </AnimatePresence>
  )
}

export default AllWidgetsModal