import { API ,FileAPI } from "./api";


const AddWidgets = {
  uploadFile: (file)=> FileAPI.post("/data/import/upload-excel-file",file),
  getUserData:()=>API.get(`/data/import/get-user-data`),
  getExcelData:(params)=>API.get(`/data/import/get-excel-data?dataId=${params}`),
  dataCalculation:(payload)=>API.post(`/calculation`,payload),
  createWidget:(payload)=>API.post(`/widgets`,payload),
  getColumnNames:(params)=>API.get(`/data/import/get-column-names?dataId=${params}`)
}

export default AddWidgets;
