import React, { useEffect, useState } from "react";
import "./index.css";
import Popup from "../../../../Shared/Dashboard/popup";
import { GetAllDashboard } from "../../../../Redux/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserProfileService from '../../../../Services/userProfile';
import DashbaordElement from "./dashboard";



const CreatedDashboards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [userProfile, setUserProfile] = useState(() => new UserProfileService().getUserProfile());
  const { fullName, email, photoProfile } = userProfile;


  const alldashboards = useSelector((state) => state.dashboard.userDashboards)?.dashboards;
  const isLoading = useSelector((state) => state.dashboard.isDashbaordsLoading);

  const [isPopUpActive, setIsPopupActive] = useState(false);

  useEffect(() => {
    dispatch(GetAllDashboard());
  }, []);

  const handlePopup = () => {
    setIsPopupActive(true);
  };


  return (
    <div className="w-full h-auto flex flex-col gap-4 px-10 pt-10 relative   ">
      <h2 className="text-[#101828] text-[20px] font-Inter font-bold ">
        Hi, {fullName}
      </h2>
      <span className="text-new_gray_700 text-[16px] font-Inter ">
        Welcome onboard! Here's some quick resources get you started with
        Baiynah.
      </span>
      {/**Dashbaords**/}
      <div className="w-full h-auto flex flex-wrap gap-4 mt-[20px] ">
     

        {alldashboards?.length > 0 &&
          alldashboards.map((dashbaord, index) => {
            return (
             <DashbaordElement dashbaord={dashbaord} />
            );
          })}

        <div
          onClick={handlePopup}
          style={{
            width: alldashboards?.length == 0 && "100%",
            height: alldashboards?.length == 0 && "250px",
          }}
          className="w-[220px] h-[370px] border-stroke flex items-center justify-center cursor-pointer gap-6 flex-col   active:scale-95 transition-all  "
        >
          <img
            style={{ height: alldashboards?.length == 0 && "65px" }}
            src="/media/dashboard/plus.svg"
          />
          <h2 className="text-[18px] text-new_purple_600 font-Inter font-semibold ">
            Create Dashboard
          </h2>
        </div>
      </div>
      {/****/}
      <Popup
        setIsPopupActive={setIsPopupActive}
        isPopUpActive={isPopUpActive}
      />
    </div>
  );
};

export default CreatedDashboards;
