import Footer from "../Shared/Screens/Footer";
import Header from "../Shared/Screens/Header";

export default function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen bg-black">
      <Header />
      <main className="grow overflow-hidden">{children}</main>
      <Footer />
    </div>
  );
}
