import { API  } from "./api";


const Dashboard = {
  createDashboard: (payload)=> API.post("new/dashboard",payload),
  getAllDashboads:()=>API.get("new/dashboards"),
  getSingleDashboard:(params)=>API.get(`dashboard?dashboardId=${params}`),
  updateDashboard:(payload)=>API.put(`dashboard/widgets`,payload),
  getUserWidgets: () => API.get(`widgets`),
  getDashboardSpecificWidgets: (dashboardID) => API.get(`dashboard/widgets?dashboardId=${ dashboardID }`),
  loadNewWidgetToDashboard: (widgetId, dashboardId) => API.get(`/dashboard/load-widget/${ widgetId }?dashboardId=${ dashboardId }`),
  saveDashboardChanges: (payload) => API.post("/dashboard/builder", payload),
  deleteDashboard:(id)=>API.put(`new/dashboard?dashboardId=${id}`),
  deleteWidgetFromUser:(id)=>API.put(`widgets/${id}`),
  favouriteDashboard:(id)=>API.put(`new/dashboard-favourite?dashboardId=${id}`)
}

export default Dashboard;
