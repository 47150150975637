import React, { useState } from 'react'
import Spinner from "../../../../../../../../components/spinner"
import AllDataSources from 'src/Services/data-sources'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const ZohoBook = ({setCurrentTab}) => {

    const [isLoading,setIsLoading] = useState(false)
    const [organizationId,setOrganizationId] = useState("")

    const handleConnect = async () => {

      if (!organizationId) {
        toast.warning(`Please Add Organization Id`, {
          position: "top-right",
      },5000)
        return
      }


        try {
          setIsLoading(true);
          const res = await AllDataSources.connectZoho(organizationId);
    
          if (res) {
            setIsLoading(false);
            // setIsConnect(true);
            // window.open();
            const width = 500;
            const height = 600;
            const left = window.screenX + (window.innerWidth - width) / 2;
            const top = window.screenY + (window.innerHeight - height) / 2;
    
            window.open(
                res.authURL, // URL from response
              "GoogleSheetPopup", // Name of the popup window
              `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars`
            );
          }
        } catch (error) {
          setIsLoading(false);
        //   setIsConnect(false);
        }
    };

    const handleOrganizationId = (e) => {
      const {value} = e.target;
      setOrganizationId(value)
    }

    console.log(organizationId,"ID")

  return (
    <div className='w-full h-full flex flex-col'>
          <div className="w-full h-[80px] flex items-center justify-between  gap-4 border-b-[1px] px-6 ">
            <img src='/media/data-sources/quick-book.svg' className='h-[35px]' />
            <div className=' inline-flex flex-col  ' >
           
         </div>
         <h2 onClick={()=>setCurrentTab("AllSources")} className=' cursor-pointer ' >Back</h2>
      </div>
      <div className='w-[500px] h-[calc(100%-80px)] flex items-center flex-col gap-6 mx-auto overflow-y-auto mt-[4%]'>
        <img src='/media/vectors/connect.png' alt='Zoho Account' className='h-[35%]' />
        <h2 className='text-[25px] font-bold font-Inter' >Zoho Books</h2>
        <p className='text-[14px] font-Inter font-normal text-center'>
        "Effortless Zoho integration to streamline your workflow and boost productivity."
        </p>
        <input onChange={handleOrganizationId} type='number' placeholder='You Organization Id'  className='w-[100%] h-[45px] mx-auto outline-none border-[1px] px-4 py-2 rounded-md' ></input>
        <button
         onClick={handleConnect}
          className='w-full h-[60px] text-white bg-[#039855] hover:bg-[#039855e0] active:scale-90 transition-all rounded-md cursor-pointer inline-flex items-center justify-center font-Inter font-semibold'
        >
          {isLoading ? <Spinner /> : "Connect to Zoho"}
        </button>
      </div>
    </div>
  )
}

export default ZohoBook