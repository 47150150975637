import React, { useState } from 'react'
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from '../../../../Services/dashboard';
import DeleteConfirmation from '../../../../components/cancel';
import { GetAllDashboard } from "../../../../Redux/dashboardSlice";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'


const DashbaordElement = ({dashbaord}) => {

   const [isFavourite,setFavourite] = useState(dashbaord?.favourite)
   const [isActive,setIsActive] = useState(false)
   const [isLoading,setIsLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleDelete = async () => {
      setIsLoading(true)
      try {
       const id = dashbaord._id
       
        const resp = await Dashboard.deleteDashboard(id)
        console.log(resp)
        if (resp.message == "Dashboard and its widgets marked as deleted successfully") {
          toast.success(`Dashbaord Deleted Successfully`, {
            position: "top-right",
        },5000)
        dispatch(GetAllDashboard());
        setIsActive(false)
        setIsLoading(false)
        }
        else{
          toast.error(`Error While Deleting Dashboard `, {
            position: "top-right",
        },5000)
        }
        
      } catch (error) {
     
          toast.error(`Error While Deleting Dashboard `, {
            position: "top-right",
        },5000)
        setIsLoading(false)

        setIsActive(false)
       
      }
    }

    const handleFavourite = async () => {
     
      setFavourite(!isFavourite)

      const resp = await Dashboard.favouriteDashboard(dashbaord._id)
        
      toast.success(resp.message, {
        position: "top-right",
    },5000)


    }



  return (
    <div  className=" h-[370px]  cursor-pointer flex flex-col gap-2 border-[1px] rounded-lg ">

    {/* <div onClick={handleDelete} className="options h-[60px] items-center justify-end px-4 z-[1000] shadow-md py-2 border-[1px] " >
          <img  src="/media/dashboard/delete.svg" className="h-[45px] hover:scale-110 active:scale-90 transition-all delte" />
    </div> */}
    <div onClick={() => navigate(`/edit/dashboard/${dashbaord._id}`, {state: { name: dashbaord?.name } })} className='w-[220px] h-full flex flex-col ' >
    <div className="w-full h-[50%] bg-new_purple_700  flex flex-col gap-4 items-center justify-center ">
      <img src="/media/login/baiynah-white.svg" />
      <h2 className="text-[12px] text-white font-Inter">
       {dashbaord.name}
      </h2>
    </div>
    <div className="w-full h-[50%] flex items-start flex-col">
      <div className="flex items-start flex-col py-3 px-4  gap-2 ">
        <img
          src="/media/dashboard/briefcase.svg"
          className="h-[20px]"
        />
        <h2 className="text-[14px] font-Inter font-semibold break-words ">
        {dashbaord.name}
        </h2>
        <p className="text-[12px] font-Inter text-new_gray_700">
          In this video, Cem will guide you through the process of
          creating your inaugural.
        </p>
      </div>
      
    </div>
   
    </div>
    <div className="w-full h-[60px] px-2 gap-4 flex items-center justify-end border-t-[1px] cursor-pointer hover:text-new_purple_700 ">
        {/* <h2 className="text-[14px] font-Inter font-semibold ">
          {" "}
          Open Dashboard
        </h2> */}
        <img onClick={handleFavourite} src={`/media/dashboard/heart-${isFavourite ? "red":"gray"}.svg`} className='h-[20px] transition-all hover:scale-110 cursor-pointer active:scale-90' />
        <img  onClick={()=>setIsActive(true)} src="/media/dashboard/delete.svg" className="h-[25px] hover:scale-110 active:scale-90 transition-all delte" />

    </div>

    <DeleteConfirmation isActive={isActive} setIsActive={setIsActive} handleDelete={handleDelete} isLoading={isLoading}/>

  </div>
  )
}

export default DashbaordElement