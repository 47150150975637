import React,{useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {motion,useAnimationControls,AnimatePresence} from "framer-motion"
import Dashboard from "src/Services/dashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const containerVariants = {
    close:{
      width:"0px",
      display:"none",
      zIndex:"1000",
      transition:{
        type:"spring",
        damping:15,
        duration:0.2
      }
    },
    open:{
      width:"360px",
      position:"absolute",
      display:"flex",
      top:0,
      right:0,
      transition:{
        type:"spring",
        damping:15,
        duration:0.2,
      

      }
    }
  }

const AddWidget = ({isSideBarActive,setisSidebarActive}) => {

   const dispatch = useDispatch()

    const [userWidgets, setUserWidgets] = useState([]);
    const containerControls = useAnimationControls()

    const handleClose = () => {
        setisSidebarActive(false)
    }


    const addWidgetToDashboard = (event) => {
      let widgetID = null;

      if (event.target.classList.contains("widget")) {
        widgetID = event.target.dataset.id;
      }
      else {
        widgetID = event.target.closest(".widget").dataset.id;
      }

      const newWidget = new CustomEvent("addWidgetToPlayGround", {
        detail: {
          message: "Add new widget to Dashboard",
          widgetId: widgetID
        }
      });

      window.dispatchEvent(newWidget);
    }

    useEffect(()=>{
        if(isSideBarActive){
          containerControls.start("open")
        }
        else{
          containerControls.start("close")
        }
      },[isSideBarActive])


    

    useEffect(() => {
      async function loadUserCreatedWidgets () {

            // Fetch all widgets for selection
            const userWidgetsResponse = await Dashboard.getUserWidgets();
            
            setUserWidgets(pState => [
                ...userWidgetsResponse.data
            ]);
        }

        loadUserCreatedWidgets();
    }, []);

      const handleDelete = async (id) => {
    
          const resp = await Dashboard.deleteWidgetFromUser(id)
          
            toast.success(resp.message, {
              position: "top-right",
          },5000)

         
          const filtered = userWidgets.filter((widget,index)=>{
            return widget.id !== id
          })

          setUserWidgets(filtered)

        
        
      }

    

  return (
    <motion.div
    variants={containerVariants}
    animate={containerControls}
    initial="close">
    <div className={` w-full  max-h-[calc(100vh-70px)] bg-white border-l-[1px] pt-2 shadow-md z-[1000]  flex flex-col gap-2`} >
      <div className='w-full h-[60px]  flex items-center justify-between px-6 flex-shrink-0 whitespace-nowrap  ' >
        <h2 className='font-Inter font-semibold text-[16px]'  >Add Widgets</h2>
        <img onClick={handleClose} src='/media/add-widgets/close.svg' className='cursor-pointer'/>
      </div>

      <span className='w-[100%]  h-[1px] bg-[#e5e7eb]  ' ></span>

      {/**Search Bar**/}

      <div className='w-full h-[50px] flex flex-row px-4 mt-4  flex-shrink-0 whitespace-nowrap   ' >
       <input type='text' placeholder='Search Resource' className='w-full p-2  outline-none font-Inter bg-[#F2F4F7] '/>  
      </div>

      {/***ALL WIDGETS***/}

      <div className='w-full h-auto flex flex-col gap-4 px-4 mt-6 overflow-y-scroll whitespace-nowrap ' >
        {/***WIDGETS ***/}

        {
          userWidgets?.length ? userWidgets.map(w => (
              <div   className='w-full flex-shrink-0 whitespace-nowrap h-[50px] cursor-pointer  flex gap-2  items-center justify-between  ' >
                <div onClick={ addWidgetToDashboard } key={ w.id } data-id={ w.id } className='widget w-[100%] h-[50px] border-[1px] flex items-center  px-4  active:scale-90 transition-all' >
                  <h2 className='font-Inter  text-[18px] active:scale-90 transition-all'>{ w.name }</h2>
                </div>
                
                <img onClick={()=>handleDelete(w.id)} src='/media/dashboard/delete.svg' alt="plus icon" className=' h-[20px] z-50  active:scale-90 transition-all '/>
              </div>
          ))
          :
          <div className='w-full h-[50px] flex-shrink-0  cursor-pointer active:scale-90 transition-all flex gap-2 border-[1px] items-center justify-between px-4 ' >
            <h2 className='font-Inter  text-[18px]' >Widgets not found</h2>
          </div>
        }
        <div className='w-full h-[30px] block' >

        </div>

      </div>
    </div>
    </motion.div>
  )
}

export default AddWidget