import React, { useState } from 'react'
import AuthApi from '../../Services/Auth'
import Alert from '../../components/Alert'
import Spinner from '../../components/spinner'

import { useNavigate } from 'react-router-dom'

const LoginForm = ({isLoading,setLoading}) => {

  const navigate = useNavigate()

  const [isError,setError] = useState(false)
  const [message,setMessage] = useState("")
  const [form,setForm] = useState({
    email:"",
    password:""
  })

  const handleInput = (e) => {
    const {name,value} = e.target
    setForm({
      ...form,
      [name]:value
    })

  }

  const handleLogin = async () => {
    setError(false)
    setMessage(" ")
    setLoading(true)

    try{
      const resp = await AuthApi.userLogin(form);
      console.log(resp,"RES")
      setError(false)
      setLoading(false)
      setMessage(" ")
      AuthApi.setSession(resp.token)

    } catch(error){
      setLoading(false);
      setError(true)
      setForm((prev)=>({...prev,password:""}))
    

      if(!error.response){
        setMessage("🌐 Newtork Issue")
      }
      else{
        setMessage(error?.response?.data?.error)
      }
      console.log(error,"Error")
    }
   
  }

  const disabled = !form.email ||!form.password;

  return (
    <div className='w-full  flex flex-col gap-6  ' >
     {
       isError &&
        <Alert
         message={message}
         closeAction={() => setError(false)}
      />
      }
      { /***Input Email***/}
      <div className='w-full flex flex-col gap-1' >
       <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Email</span>
       <input onChange={handleInput} name='email' value={form.email} type='email' placeholder='example@gmail.com' className='px-3 py-2 border-[1px] focus:border-new_purple_500 border-[#D0D5DD] shadow-[shadow_inputs]  outline-none rounded-md' />
      </div>
      { /***Input Password ***/}
      <div className='w-full flex flex-col gap-1' >
       <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Password</span>
       <input  onChange={handleInput} name='password' value={form.password} type='password' placeholder='*****************' className='px-3 py-2 focus:border-new_purple_500  border-[1px] border-[#D0D5DD] shadow-[shadow_inputs]  outline-none rounded-md' />
       <span onClick={()=>navigate("/forgot-password")} className=' inline-flex justify-end mt-[5px] text-new_gray_500 font-Inter text-[14px] cursor-pointer hover:text-new_purple_600 ' >Forgot Password</span>
      </div>
      
      {/**SIGN UP Button**/}
      <div className='w-full flex flex-col gap-1' >
        
       <button onClick={handleLogin} disabled={disabled} className={`p-3 bg-new_purple_600 disabled:cursor-not-allowed disabled:bg-hovered_new_purple_600  hover:bg-hovered_new_purple_600 ${!disabled && "active:scale-95" }  transition-all text-white font-Inter rounded-md `} >
       
        {
          isLoading ?
           <Spinner/>
          :
          " SIGN IN"
        }
       </button>
      </div>
      {/**Navigate**/}
      <span className='flex items-center justify-center flex-row gap-1' >
        <p className='font-Inter font-normal text-[14px] text-new_gray_500 ' >You Don’t have an account yet?</p>
        <p onClick={()=>navigate("/register")} className='font-Inter font-normal cursor-pointer text-[14px] text-new_purple_600 ' >Sign Up</p>
      </span>
     
    </div>
  )
}

export default LoginForm