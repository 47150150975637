import React, { useState } from 'react'
import {useDispatch} from "react-redux"
import { setCurrentChart } from '../../../../../Redux/chartsSlice'

const WidgetSelector = () => {

    const dispatch = useDispatch()


    const [activeTab,setActiveTab] = useState("number")

    const activeClass = 'bg-white border-[1px] border-new_gray_200 '
    const deActiveClass = ''


    const handleTab = (tab) => {
        setActiveTab(tab)
        dispatch(setCurrentChart(tab))
        
    }


  return (
    <div className='w-full h-[45px] bg-[#F8F9FC] border-[1px] border-new_gray_100 rounded-lg flex flex-row items-center gap-2 p-2 ' >
        <div onClick={()=>handleTab("number")} className={`w-1/6 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "number" ? activeClass : deActiveClass }  `} >
            123
        </div>
        <div onClick={()=>handleTab("gauge")} className={`w-1/6 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "gauge" ? activeClass : deActiveClass }  `} >
            <img src='/media/add-widgets/meter.svg' className='h-[20px]' />
        </div>
        <div onClick={()=>handleTab("line")} className={`w-1/6 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "line" ? activeClass : deActiveClass }  `} >
            <img src='/media/add-widgets/growth.svg' className='h-[20px]'/>
        </div>
        <div onClick={()=>handleTab("bar")}  className={`w-1/6 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "bar" ? activeClass : deActiveClass }  `} >
            <img src='/media/add-widgets/menu.svg' className='h-[20px]'/>
        </div>
        <div onClick={()=>handleTab("menu2")} className={`w-1/6 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "menu2" ? activeClass : deActiveClass }  `} >
            <img src='/media/add-widgets/menu-2.svg' className='h-[20px]'/>
        </div>
        <div onClick={()=>handleTab("table")} className={`w-[45px] h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "table" ? activeClass : deActiveClass }  `} >
            <img src='/media/add-widgets/table.svg' className='h-[20px]'/>
        </div>
       
    </div>
  )
}

export default WidgetSelector